import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';

const genderLabel = (gender) => (gender === 'male' ? '男' : '女');

const EXPORT_COLLECTION = 'admin_user';
const EXPORT_TITLE_DEFAULT = '工作人員匯出';
const EXPORT_FIELDS = [
  {key: '__serial_number', name: '流水號'},
  {key: 'name', name: '姓名'},
  {key: 'group', name: '單位'},
  {key: 'role', name: '職稱'},
  {key: 'username', name: '帳號'},
  {key: 'gender', name: '性別'},
  {key: 'birthday', name: '生日'},
  {key: 'arrived_date', name: '到職日'},
  {key: 'leaved_date', name: '離職日'},
  {key: '__address', name: '通訊地址'},
  {key: 'tel', name: '聯絡電話'},
  {key: 'phone', name: '手機'},
  {key: 'status', name: '狀態'},
  {key: 'note', name: '備註'},
];

function AdminStaffExport(props) {
  return (
    <Wrapper>
      <GenericForm
        instance={null}
        schema={{
          title: '',
          type: 'object',
          required: ['title', 'selectAllFields'],
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: EXPORT_TITLE_DEFAULT,
            },
            status: {
              type: 'string',
              title: '狀態',
              enum: ['在職', '離職'],
            },
            selectAllFields: {
              type: 'boolean',
              title: '匯出欄位全選',
              enum: [true, false],
              enumNames: ['是', '否'],
              default: true,
            },
          },
          dependencies: {
            selectAllFields: {
              oneOf: [
                {
                  required: ['fields'],
                  properties: {
                    selectAllFields: {enum: [false]},
                    fields: {
                      type: 'array',
                      title: '匯出欄位選擇',
                      items: {
                        type: 'string',
                        enum: EXPORT_FIELDS.map((field) => field.key),
                        enumNames: EXPORT_FIELDS.map((field) => field.name),
                      },
                      uniqueItems: true,
                    },
                  },
                },
              ],
            },
          },
        }}
        uiSchema={{
          selectAllFields: {
            'ui:widget': 'radio',
          },
          fields: {
            'ui:widget': 'checkboxes',
          },
        }}
        onSubmit={(formData) => {
          const {status, selectAllFields, fields} = formData;

          let query = {};
          let projection = {};

          if (!!status) {
            query['status'] = status;
          }

          let selectedFields = selectAllFields
            ? EXPORT_FIELDS
            : EXPORT_FIELDS.filter(
                (obj) => fields.findIndex((key) => key === obj.key) > -1,
              );
          let excelProps = {};

          for (let i = 0; i < selectedFields.length; i++) {
            if (selectedFields[i].key.indexOf('__') === -1) {
              projection[selectedFields[i].key] = 1;
            }

            if (selectedFields[i].key === '__address') {
              projection['address_zip_code'] = 1;
              projection['address_city'] = 1;
              projection['address_detail'] = 1;
            }
          }

          excelProps = {
            collection: EXPORT_COLLECTION,
            filename: `${formData.title || EXPORT_TITLE_DEFAULT}.xlsx`,
            query,
            projection,
            recordsToAoa: async (records) => {
              return [
                [...selectedFields.map((field) => field.name)],
                ...records.map((record, index) => {
                  const returnValues = selectedFields.map((field) => {
                    if (field.key === '__serial_number') {
                      return `${index + 1}`;
                    } else if (field.key === '__address') {
                      return `${record.address_zip_code || ''} ${
                        record.address_city || ''
                      } ${record.address_detail || ''}`;
                    } else if (field.key === 'gender') {
                      return genderLabel(record.gender);
                    }
                    return record[field.key];
                  });

                  return [...returnValues];
                }),
              ];
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminStaffExport;
