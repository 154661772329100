import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import withPageEntry from '../../withPageEntry';
import AdminStaffExport from './AdminStaffExport';

function AdminReportPermissionList(props) {
  const [selected, setSelected] = React.useState(0);
  return (
    <Wrapper>
      <div className="row">
        <h1>權限報表管理</h1>
        <div className="buttons-container">
          <Button
            className={`btn ${selected === 0 && 'selected'}`}
            onClick={() => setSelected(0)}>
            工作人員匯出
          </Button>
        </div>
      </div>

      <div className="content-container">
        {selected === 0 && <AdminStaffExport />}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > .row {
    display: flex;
    align-items: center;

    & > h1 {
      margin-bottom: 10px;
      font-size: 32px;
    }

    & > div.buttons-container {
      margin-left: 10px;
      margin-bottom: 10px;

      & .btn {
        margin-top: 5px;
        margin-right: 10px;
        margin-bottom: 5px;
      }

      .selected {
        background-color: var(--primaryColor);
        color: #ffffff;
      }
    }
  }

  & > div.content-container {
    border-top: 1px dashed #868e96;
    padding-top: 20px;
  }
`;

export default AdminReportPermissionList;
